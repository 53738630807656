import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import drupalbg from '../assets/images/drupalbg.jpg'

const BannerLanding = props => (
  <section
    style={
      props.background
        ? {
            backgroundImage: `url(${props.background})`,
            backgroundSize: 'contain',
            backgroundPosition: 'top',
          }
        : null
    }
    id="banner"
    className="style1"
  >
    <div className="inner">
      <header className="major">
        <h1>ASU UTO, Apps & Design</h1>
      </header>
      <div className="content">
        <p>My first real job.</p>
      </div>
    </div>
  </section>
)

const WhatAppDes = props => (
  <section id="one">
    <div className="inner">
      <header className="major">
        <h2 id="whatappdes">What is Apps & Design?</h2>
      </header>
      <div>
        <p>
          Apps & Design is one of several development departments within UTO.
          The group is primarily responsible for building and maintaining
          systems realted to the deployment of organizational websites within
          the University.
        </p>
        <p>
          This includes the development of "Webspark", a Drupal distribution
          built and styled to meet ASU Web Standards, as well as the creation of
          various Drupal modules to meet the requirements of colleges and
          organizations across the university.
        </p>
        <p>
          The group assists said organizations with the deployment and
          maintenance of Webspark sites on the hosting platform{' '}
          <a href="https://pantheon.io/">Pantheon</a>.
        </p>
      </div>
      <h3> Some Links </h3>
      <ul>
        <li>
          <a href="https://uto.asu.edu/">ASU UTO</a>
        </li>
        <li>
          <a href="https://github.com/ASU/webspark-drops-drupal7">
            Webspark Repo
          </a>
        </li>
        <li>
          <a href="https://github.com/ASU/asu-header">ASU Header Repo</a>
        </li>
        <li>
          <a href="https://github.com/ASU/asu-drupal-modules">
            Drupal Modules Repo
          </a>
        </li>
      </ul>
    </div>
  </section>
)

const WhatMyRole = props => (
  <section id="two">
    <div className="inner">
      <header className="major">
        <h2 id="whatappdes">My Role</h2>
      </header>
      <p>
        My role within Apps & Design was primarily Drupal Developer/Sitebuilder,
        and I was responsible for several aspects of Webspark and Web Standards
        development. These included various college sites, Drupal modules and
        standardized aspects of ASU sites such as the ASU Global Header.
      </p>
      <h2>Responsibilities</h2>
      <h3>ASU Request for Information</h3>
      <div>
        <p>
          The first project I was given ownership of was the ASU RFI module,
          which was supposed to be a plug & play solution for new college sites
          to set up a "Request for Information" form on any page of their Drupal
          site so that prospective students could get additional information on
          college degrees.
        </p>
        <p>
          This project required me to work with various organizations across the
          university including the Marketing Hub, EOSS & Ops to deliver a
          product that would assist in onboarding new students.
        </p>
      </div>
      <h3>ASU Global Header & Footer</h3>
      <div>
        <p>
          In order to be Web Standards compliant, every site must include the
          ASU Global Header & Footer at the top and bottom of every web page
          respectively.
        </p>
        <p>
          The footer is a straightforward piece of HTML that houses ASU's
          Innovation badge along with various links to important information.
        </p>
        <p>
          The header includes important links just like the footer, however it
          also houses an ASU Search bar (powered by Google) that allows users to
          search for information both at the site being viewed or across all
          college sites simultaneously.It also acts as an integral part for
          various systems that need to be deployed across all university sites.
        </p>
        <p>
          For example, it is an important piece in the University's Single
          Sign-on solution which allows students to maintain their authenticated
          status when browsing through various University sites back-to-back.
          SSO greatly improves the user experience and deploying it through the
          header has proven to be an extremely effective solution.
        </p>
        <p>
          The Header is also an important piece in the University's analytics
          solution. It acts as a vector to deploy ASU's Google Tag Manager code,
          so the Marketing Hub can collect important information on what drives
          engagement with ASU's web presence as well as how to best interact
          with users.
        </p>
      </div>

      <h3>Site Building</h3>

      <div>
        <p>
          There are hundreds of organizations across the University and only a
          few of them have employees that are familiar with sitebuilding. For
          that reason the Apps & Design team's main job is to assist in rolling
          out an maintaining sites for those organizations.
        </p>
        <p>
          During my time on the team I assisted several organizations including
          the Marketing Hub, EOSS and The Office of the President with various
          aspects of their Drupal sites.
        </p>
      </div>
    </div>
  </section>
)

const ASUInfo = props => (
  <Layout>
    <Helmet>
      <title>ASU, Mobile & IoT</title>
      <meta name="description" content="Mobile & IoT Software Development" />
    </Helmet>

    <BannerLanding background={drupalbg} />

    <div id="main">
      <WhatAppDes />
      <WhatMyRole />
    </div>
  </Layout>
)

export default ASUInfo
